import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import history from '../assets/images/history.png'

const History = () => {
  return (
    <div className="bg">
      <Container>
        <Row className='justify-content-center'>
          <Col xs="11" md="11" lg="9" xl="7" className='box-content my-5 py-5 text-center'>
            <h1 className='font-title mb-4'>ประวัติความเป็นมา</h1>
            <img src={history} alt="" className='mb-4 img-full'/>
            <Col xs="11" md="11" lg="10" xl="10" className='mb-5 font-span' style={{margin: "0 auto"}}>
              <div><span style={{paddingLeft: "35px"}}>โครงการลูกพระดาบส</span>เป็นโครงการตามแนวพระราชดำริ ซึ่งพระบาทสมเด็จพระเจ้าอยู่หัวพระราชทานพระบรมราชานุญาตให้จัดตั้งขึ้นเมื่อ ๑๕ มิ.ย. ๒๕๔๑ ตั้งอยู่เลขที่ ๘๙ หมู่ ๑๔ ต.บางปลา อ.บางพลี จ.สมุทรปราการ บนที่ดินราชพัสดุ และที่ดินพระราชทาน จำนวน ๔๗๕ ไร่ </div>
              <span style={{paddingLeft: "35px"}}>ซึ่ง</span>ได้ดำเนินกิจกรรมการเกษตรแบบผสมผสานในลักษณะพึ่งพาตนเองตามแนวพระราชดำริ เพื่อพัฒนาให้เป็นศูนย์กลางการเรียนรู้และถ่ายทอดเทคโนโลยีด้านการเกษตร การเพิ่มมูลค่าสินค้าเกษตร สมุนไพร การใช้พลังงานทดแทน และอื่นๆ เพื่อสนับสนุนการเรียนการสอนของศิษย์พระดาบสหลักสูตรการเกษตรพอเพียง และช่างไม้เครื่องเรือนของโรงเรียนพระดาบส การศึกษาค้นคว้าวิจัยและพัฒนา การถ่ายทอดเทคโนโลยีสู่ชุมชน การท่องเที่ยงเชิงเกษตร ตลอดจนใช้เป็นสถานที่ฝึกอบรม ปฏิบัติงาน และศึกษาดูงานของนักเรียน นิสิต นักศึกษา เกษตรกร และประชาชนทั่วไป โดยให้อยู่ในความรับผิดชอบร่วมกันของสำนักพระราชวังและมูลนิธิพระดาบส
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default History