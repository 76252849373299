import React ,  { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import useLogin from '../customHook/userLogin';

import FBLogin from '../componant/FBLogin'
import GGLogin from '../componant/GGLogin';


const Login = (props) => {

    const navigate = useNavigate();

    // Login with Email
    let {username, password, message, Userlogin, setUsername, setPassword } = useLogin();

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)
    
    useEffect(()=>{
        if (user != null){
        navigate("/")
        }
    },[])

    return (
        <div className="bg">
            <Container>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='white-box my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>กรอกข้อมูลเข้าสู่ระบบ</h1>
                        <Form className='px-05'>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" name="key" placeholder="เลขบัตรประชาชน 13 หลัก หรือ อีเมล" className='text-center input-login' value={username} onChange={e => setUsername(e.target.value)} required/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="password" name="password" placeholder="รหัสผ่าน" className='text-center input-login' value={password} onChange={e => setPassword(e.target.value)} required/>
                            </Form.Group>
                            <p className='my-2 message mb-3'>{message}</p>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type='submit' size="lg" className='btn-login font-btn' onClick={Userlogin}>เข้าสู่ระบบ</Button>
                            </div>
                        </Form>
                        <div className='py-3'>
                            <Link to="/forget" className='forget-text'>ลืมรหัสผ่าน</Link> 
                            <span style={{padding: "0 10px"}}>หรือ</span>
                            <Link to="/register" className='regis-text'>ลงทะเบียนใหม่</Link> 
                        </div>
                        <div className='mb-2'><span style={{padding: "0 20px 0 0"}}>หรือ</span></div>
                        <div className="d-grid gap-2 px-05 mb-5">
                            <GGLogin variant="outline-primary" size="lg" className='btn-login mb-3'/>
                            <FBLogin variant="outline-primary" size="lg" className='btn-login mb-3'/>
                        </div>
                        <Link to="#" style={{float: "right"}} className='px-2'>&lt; สำหรับเจ้าหน้าที่ &gt;</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Login