import React ,  { useState, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';

import { apiLink } from '../../constants/DataPublic';

const Banner = () => {

    const [banner, setBanner] = useState([]);
    const [isLoading, setLoading] = useState(false);  

    useEffect(() => {
        setLoading(true);
        getApiBanners();
    }, []);

    if (isLoading) return <p> Loading...</p>
    if (!banner) return <p> No data </p>

    const getApiBanners = async () => {
        try {
            const requestOption = {
                "method": "GET"
            }
        
            const response = await fetch(
                apiLink+"/banner/showBanner" , requestOption).then((response) => response.json());
            setBanner(response.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Carousel className='p-0'>
            {banner.map((data)=>{
                return (
                    <Carousel.Item key={data.seqno}>
                        <a href={data.banner_url} target="_blank"><img src={data.banner_image} alt={data.banner_text} className='banner'/></a>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}
export default Banner