import React, {useState, useEffect}  from 'react';
import { Link, useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import useRegister from '../customHook/useRegister';

import Modal from 'react-bootstrap/Modal';
import Close from '../assets/images/closed.png'


const Password  = (props) => {
    

    let {show, showfalse, password, message, setPassword, handleClose, confirmPass, handleClosefalse, checkUserRegis, deleteUser } = useRegister();

    useEffect(() => {
        checkUserRegis();
    }, []);

    const navigate = useNavigate;

    return (
        <div className="bg">
            <Container style={{height: "100vh", display: "grid", alignItems: "center"}}>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='white-box my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>ยืนยันและกรอกรหัสผ่าน</h1>
                        <Form className='px-05'>
                            <Form.Group className="mb-3 text-left">
                                <Form.Control placeholder="รหัสผ่าน" minLength={8} value={password} onChange={e => setPassword(e.target.value)} type='password' name='password' className='text-center input-login mb-3' required/>
                                <p className='my-2 message px-2'>{message}</p> 
                                <ul>
                                    <li className="font-span" style={{color: "#C18A61"}}>ความยาวขั้นต่ำ 8 ตัวอักษร</li>
                                </ul>
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button onClick={confirmPass} type='submit' variant="primary" size="lg" className='btn-login font-btn'>บันทึกข้อมูล</Button>
                            </div>
                        </Form>

                        <Modal show={show} onHide={handleClose} className="register">
                            <Modal.Header style={{borderBottom: 0, justifyContent: "end"}}>
                                <Link to="/login" ><img src={Close}/></Link>
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <p>การสมัครสมาชิกของคุนสำเร็จ</p>
                                    <Button href="/login" type='submit' variant="primary" size="lg" className='btn-login font-btn' style={{width: "40%"}}>เข้าสู่ระบบ</Button>
                                
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>

                        <Modal show={showfalse} onHide={handleClosefalse} onLoad={deleteUser} className="register">
                            <Modal.Header style={{borderBottom: 0, justifyContent: "end"}}>
                                <Link to="/register" ><img src={Close}/></Link>
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <p>ลิ้งค์ของคุณหมดอายุแล้ว กรุณาลงทะเบียนใหม่อีกครั้ง</p>
                                <Button href="/register" type='submit' variant="primary" size="lg" className='btn-login font-btn' style={{width: "40%"}}>ลงทะบียน</Button>
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Password