import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiLink } from '../constants/DataPublic'

const useRegister = () => {
    const [base64Data, setBase64Data] = useState(null);

    const [password, setPassword] = useState('');
    const {id } = useParams();

    const [title, setTitle] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [idcard, setIdcard] = useState('');
    const [mobile, setMobile] = useState('');
    const [lineid, setLineid] = useState('');
  
    const [message, setMessage] = useState("");
    const [titlemessage, setTitleMessage] = useState("");
    const [messageFName, setMessageFName] = useState("");
    const [messageLName, setMessageLName] = useState("");
    const [messageemail, setMessageEmail] = useState("");
    const [messageidcard, setMessageIDCard] = useState("");
    const [messagemobile, setMessageMobile] = useState("");
    const [registerText, setRegisterText] = useState("");

    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [showfalse, setShowfalse] = useState(false);
    const handleShowfalse = () => setShowfalse(true);
    const handleClosefalse = () => setShowfalse(false);

    const [successShow, setSuccessShow] = useState(false);
    const successRegis = () => setSuccessShow(true);
    const successClose = () => setSuccessShow(false);

    const [failedShow, setFailedShow] = useState(false);
    const failedRegis = () => setFailedShow(true);
    const failedClose = () => setFailedShow(false);

    const [titlename, setTitleName] = useState([]);

    const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

    // const checkRegis = (e) => {
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     setInPuts(values => ({...values, [name]: value }))
    // }
    
    const getApiTitle = async () => {
        try {
            const requestOption = {
                "method": "GET"
            }
            const response = await fetch(
                apiLink+"/member/showTitle" , requestOption).then((response) => response.json());
            setTitleName(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const selectmage = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Data(reader.result)
            };
            reader.readAsDataURL(file);
        } else {
            setBase64Data(null);
        }
    };

    const saveRegis = (e) => {
        e.preventDefault();

        const image64 = base64Data ? base64Data.split(',')[1] : null;
      
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (title === '') {
            setTitleMessage("กรุณาเลือกคำนำหน้า")
        } else if(firstname === '') {
            setMessageFName("กรุณาระบุชื่อ")
        } else if (lastname === '') {
            setMessageLName("กรุณาระบุนามสกุล")
        } else if (email === '') {
            setMessageEmail("กรุณาระบุอีเมล")
        } else if (regex.test(email) === false) {
            setMessageEmail("กรุณาระบุอีเมลให้ถูกต้อง")
        } else if (idcard === '' || idcard.length < 13) {
            setMessageIDCard("กรุณาระบุหมายเลขบัตรประชาชน")
        } else if (mobile === '') {
            setMessageMobile("กรุณาระบุเบอร์โทรศัพท์")
        } else {

            const raw = JSON.stringify({
                "title": title, 
                "firstname": firstname, 
                "lastname": lastname, 
                "email": email, 
                "idcard": idcard, 
                "mobile": mobile, 
                "lineid": lineid,
                "image64": image64,
            })

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            // console.log(base64Data.split(',')[1])

            fetch (apiLink+'/member/register', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result["result"] === true){
                    //   console.log("ลงทะเบียนสำเร็จ")
                    //   console.log(result["message"])
                    setRegisterText("ระบบส่งข้อความยืนยันไปที่อีเมล์ของคุณ กรุณากด “ยืนยัน” ในอีเมล์ที่ลงทะเบียน เพื่อกรอกรหัสผ่าน")
                    successRegis();
                    
                } else {
                    console.log("ลงทะเบียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
                    //   console.log(result["message"])
                    setRegisterText(result["message"])
                    failedRegis();
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
        }
    }
    

    const deleteUser = () => {
        
        const body = JSON.stringify({
            "member_id": id
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body,
        };

        fetch(apiLink+"/member/deleteMember", requestOptions)
        .then(response => response.json())
        .then(result => {
            
            if(result["result"] == true) {
                console.log("result", result["result"])
                console.log("result", result["message"])
            } else {
                console.log("result", result["message"])
            }
        })
    }

    const checkUserRegis = () => {
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(apiLink+"/member/checkToken/"+id, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result["result"] == false) {
                console.log("checkUser", result["result"])
                handleShowfalse()
            } else {
                console.log("checkUser", result["result"])
            }
        })
    }

    const confirmPass = async (e) => {
        e.preventDefault();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(apiLink+"/member/checkToken/"+id, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result["result"]) {
                setUsers(result["data"][0])
                // console.log(result["data"][0])

                if (password == '') {
                    setMessage("กรุณาระบุรหัสผ่าน")
                } else if (password.length < 8) {
                    setMessage("กรุณาระบุรหัสผ่านความยาวขั้นต่ำ 8 ตัวอักษร")
                } else {
                    setMessage("")
                    const body = JSON.stringify({
                        "member_id": users["member_id"],
                        "firstname": users["firstname"],
                        "lastname": users["lastname"],
                        "email": users["email"],
                        "password": password
                    })

                    // console.log(body)

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: body,
                    };
                    
                    fetch (apiLink+'/member/savePassword', requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log("savePassword", result["result"])
                        handleShow();
                    })
                }
            } 
        })
    }

    return {titlename, title, firstname, lastname, email, idcard, mobile, lineid, setTitleName, getApiTitle, setTitle, setBase64Data, setFirstname, setLastname, setEmail, setIdcard, setMobile, setLineid, show, showfalse, successShow, failedShow, registerText, users, message, password, id, titlemessage, messageFName, messageLName, messageemail, messageidcard, messagemobile, base64Data, selectmage, setPassword, handleClosefalse, saveRegis, successClose, failedClose, confirmPass, handleClose, deleteUser, checkUserRegis }

}
export default useRegister