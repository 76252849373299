import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Objective = () => {
  return (
    <div className="bg">
            <Container>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='box-content my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>วัตถุประสงค์/เป้าหมาย</h1>
                        <Col lg="10" className='text-center font-span margin-auto mb-5'>
                        โครงการพระดาบสเป็นโครงการตามกระแสพระราชดำริของพระบาทสมเด็จพระเจ้าอยู่หัว ตั้งขึ้นเมื่อปี 2518 มีวัตถุประสงค์เพื่อสนับสนุนผู้ด้อยโอกาสทางการศึกษาที่ขาดแคลนทุนที่ทรัพย์ยังไม่มีอาชีพ และความรู้พื้นฐานเพียงพอที่จะเข้าศึกษาต่อในสถาบันการศึกษาชั้นสูง หากแต่มีความยินดีสนใจใฝ่ศึกษาและมีความเพียรอย่างจริงจังให้ได้รับโอกาสฝึกวิชาชีพ และได้ฝึกอบรมคุณธรรมศีลธรรม โดยมีจุดมุ่งหมายเพื่อให้เขาเหล่านั้นออกไปประกอบสัมมาอาชีวะ สร้างตนเอง ช่วยเหลือครอบครัว สังคม และประเทศชาติ
                        </Col>

                        <Col lg="10" className='text-center font-span margin-auto mb-5'>
                        เมื่อปี 2519 พระบาทสมเด็จพระเจ้าอยู่หัวได้พระราชทานกระแสพระราชดำริให้ดำเนินการทดลองเปิดหลักสูตรวิชาช่างไฟฟ้า วิทยุ โดยได้รับพระมหากรุณาธิคุณพระราชทานพระราชทรัพย์ส่วนพระองค์เป็นทุนประเดิมในการดำเนินโครงการ และต่อมาได้ทรงพระกรุณโปรดเกล้าฯ พระราชทานพระบรมราชานุญาตให้จดทะเบียนจัดตั้งเป็นมูลนิธิพระดาบสตามกฎหมายเมื่อวันที่ 17 สิงหาคม พ.ศ.2533
                        </Col>
                    </Col>
                </Row>
            </Container>
        </div>
  )
}
export default Objective