import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import banner from '../assets/images/Banner-2.png'
import QR from '../assets/images/QR.png'
import QRPayment from '../assets/images/QR-2.png'

const EnrollCourse = () => {

    const [ShowEnrollCourse, setShowEnrollCourse] = useState(false);
    const handleCloseEnrollCourse = () => setShowEnrollCourse(false);
    const handleShowEnrollCourse = () => setShowEnrollCourse(true);

    const [ShowQR, setShowQR] = useState(false);
    const handleCloseQR = () => setShowQR(false);
    const handleShowQR = () => setShowQR(true);

    return (
        <div className="bg">
            <Container>
                <Row>
                    <Col xl="12" className='py-4'>
                        <div className='enroll-course p-4'>
                            <h1 className='font-title mb-4 text-center'>หลักสูตรที่สมัคร</h1>
                            <Col xs="12" md="12" lg="12" xl="12" className='mb-5'>
                                <Row className='mb-3'>
                                    <Col md="4" lg="3" xl="4" className='alignItems-center'>
                                        <Form.Label className='course-lable'>หลักสูตรอบรมปี : </Form.Label>
                                    </Col>
                                    <Col md="4" lg="3" xl="3">
                                        <Form>
                                        <Form.Select aria-label="Default select example input-login" required>
                                            <option>2566</option>
                                            <option value="2566">2566</option>
                                            <option value="2567">2567</option>
                                            <option value="2568">2568</option>
                                        </Form.Select>
                                        </Form>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="4" lg="3" xl="4" className='alignItems-center'>
                                        <Form.Label className='course-lable'>สถานะหลักสูตร : </Form.Label>
                                    </Col>
                                    <Col md="6" lg="6" xl="6">
                                        <Form>
                                        <Form.Select aria-label="Default select example input-login" required>
                                            <option>กรุณาเลือกสถานะหลักสูตร</option>
                                            <option value="1">ทั้งหมด</option>
                                            <option value="2">กรุณาเลือกสถานะหลักสูตร</option>
                                            <option value="3">กรุณาเลือกสถานะหลักสูตร</option>
                                        </Form.Select>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>

                            <Row className='table-enroll-course p-3 m-2'>
                                <Col md="4" lg="5" xl="6">หลักสูตร</Col>
                                <Col md="3" lg="2" xl="2">สถานะ</Col>
                                <Col md="2" lg="2" xl="2">QR Code</Col>
                                <Col md="3" lg="3" xl="2">ยกเลิกการสมัคร</Col>
                            </Row>

                            <Row className='p-3 m-2'>
                                <Col md="4" lg="5" xl="6">
                                    <Row>
                                        <Col md="12" lg="4" xl="4">
                                            <img src={banner} alt="" className='img-full'/>
                                        </Col>
                                        <Col>
                                            <p className='mb-0 enroll-course-name'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</p>
                                            <span className='enroll-course-detail'>วันที่อบรม : 4 พฤษภาคม 2565 (1 วัน)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3" lg="2" xl="2" className='text-center status-waiting'>สมัครสำเร็จ<br/>(รอยืนยัน)</Col>
                                <Col md="2" lg="2" xl="2" className='text-center'></Col>
                                <Col md="3" lg="3" xl="2" className='text-center enroll-course-detail' style={{cursor: "pointer"}} onClick={handleShowEnrollCourse}>
                                        ยกเลิกการสมัคร
                                </Col>
                            </Row>

                            <hr/>

                            <Row className='p-3 m-2'>
                                <Col md="4" lg="5" xl="6">
                                    <Row>
                                        <Col xl="4">
                                            <img src={banner} alt="" className='img-full'/>
                                        </Col>
                                        <Col>
                                            <p className='mb-0 enroll-course-name'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</p>
                                            <span className='enroll-course-detail'>วันที่อบรม : 4 พฤษภาคม 2565 (1 วัน)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3" lg="2" xl="2"className='text-center status-waiting'>สมัครสำเร็จ<br/>(รอยืนยัน)<br/>ตัวสำรอง</Col>
                                <Col md="2" lg="2" xl="2" className='text-center'></Col>
                                <Col md="3" lg="3" xl="2" className='text-center enroll-course-detail' style={{cursor: "pointer"}} onClick={handleShowEnrollCourse}>ยกเลิกการสมัคร</Col>
                            </Row>

                            <hr/>

                            <Row className='p-3 m-2'>
                                <Col md="4" lg="5" xl="6">
                                    <Row>
                                        <Col xl="4">
                                            <img src={banner} alt="" className='img-full'/>
                                        </Col>
                                        <Col>
                                            <p className='mb-0 enroll-course-name'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</p>
                                            <span className='enroll-course-detail'>วันที่อบรม : 4 พฤษภาคม 2565 (1 วัน)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3" lg="2" xl="2" className='text-center status-succeed'>ยืนยันแล้ว</Col>
                                <Col md="2" lg="2" xl="2" className='text-center'><img src={QR} alt="" className='' style={{cursor: "pointer"}} onClick={handleShowQR}/></Col>
                                <Col md="3" lg="3" xl="2" className='text-center enroll-course-detail'></Col>
                            </Row>

                            <hr/>

                            <Row className='p-3 m-2'>
                                <Col md="4" lg="5" xl="6">
                                    <Row>
                                        <Col xl="4">
                                            <img src={banner} alt="" className='img-full'/>
                                        </Col>
                                        <Col>
                                            <p className='mb-0 enroll-course-name'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</p>
                                            <span className='enroll-course-detail'>วันที่อบรม : 4 พฤษภาคม 2565 (1 วัน)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3" lg="2" xl="2" className='text-center status-cancel'>ยกเลิกแล้ว</Col>
                                <Col md="2" lg="2" xl="2" className='text-center'></Col>
                                <Col md="3" lg="3" xl="2" className='text-center enroll-course-detail'></Col>
                            </Row>

                            <hr/>

                            <Row className='p-3 m-2'>
                                <Col md="4" lg="5" xl="6">
                                    <Row>
                                        <Col xl="4">
                                            <img src={banner} alt="" className='img-full'/>
                                        </Col>
                                        <Col>
                                            <p className='mb-0 enroll-course-name'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</p>
                                            <span className='enroll-course-detail'>วันที่อบรม : 4 พฤษภาคม 2565 (1 วัน)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3" lg="2" xl="2" className='text-center course-status'>อยู่ระหว่างอบรม</Col>
                                <Col md="2" lg="2" xl="2" className='text-center'><img src={QR} alt=""  onClick={handleShowQR} style={{cursor: "pointer"}}/></Col>
                                <Col md="3" lg="3" xl="2" className='text-center enroll-course-detail'></Col>
                            </Row>

                            <hr/>

                            <Row className='p-3 m-2'>
                                <Col md="4" lg="5" xl="6">
                                    <Row>
                                        <Col xl="4">
                                            <img src={banner} alt="" className='img-full'/>
                                        </Col>
                                        <Col>
                                            <p className='mb-0 enroll-course-name'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</p>
                                            <span className='enroll-course-detail'>วันที่อบรม : 4 พฤษภาคม 2565 (1 วัน)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3" lg="2" xl="2" className='text-center course-status'>สิ้นสุดการอบรม</Col>
                                <Col md="2" lg="2" xl="2" className='text-center'></Col>
                                <Col md="3" lg="3" xl="2" className='text-center enroll-course-detail'></Col>
                            </Row>

                            <hr/>

                            <Row className='p-3 m-2'>
                                <Col md="4" lg="5" xl="6">
                                    <Row>
                                        <Col xl="4">
                                            <img src={banner} alt="" className='img-full'/>
                                        </Col>
                                        <Col>
                                            <p className='mb-0 enroll-course-name'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</p>
                                            <span className='enroll-course-detail'>วันที่อบรม : 4 พฤษภาคม 2565 (1 วัน)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3" lg="2" xl="2" className='text-center status-cancel'>ยกเลิกแล้ว<br/>(ไม่ได้ยืนยัน)</Col>
                                <Col md="2" lg="2" xl="2" className='text-center'></Col>
                                <Col md="3" lg="3" xl="2" className='text-center enroll-course-detail'></Col>
                            </Row>

                            <hr/>

                            <Modal show={ShowEnrollCourse} onHide={handleCloseEnrollCourse} className="course-enroll">
                                <Modal.Header className="modal-header-enroll">
                                    <Col xl="2" className="p-2">
                                        <img src={banner} alt="" className='img-full' />
                                    </Col>
                                    <Col xl="10" className="p-2">
                                        <h1 className='font-title'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</h1>
                                        <span className='status-waiting'>สถานะ : สมัครสำเร็จ (รอยืนยัน)</span>
                                    </Col>
                                </Modal.Header>
                                <Modal.Body className='modal-body-enroll p-4'>
                                    <Row>
                                        <Col xl="5" className='mb-5'>
                                            <Row>
                                                <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                                                <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                                                <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                                                <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                                                <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                                                <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                                            </Row>
                                        </Col>
                                        <Col xl="7" className='mb-5'>
                                            <p className='font-span'>โครงการลูกพระดาบสฯ จัดอบรมสอนการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส เมื่อวันที่ 4 พฤษภาคมที่ผ่านมา ณ โครงการลูกพระดาบสฯ ซึ่งได้รับความสนใจและความร่วมมือเป็นอย่างดี</p>
                                        </Col>

                                        <Col xl="6" className='px-5 mb-5'>
                                            <p className='font-span'><strong>วันที่อบรม : </strong>4 พฤษภาคม 2565 (1 วัน)</p>
                                            <p className='font-span'><strong>เวลาอบรม : </strong>9:00 - 17:00 น.</p>
                                            <p className='font-span'><strong>วิทยากร : </strong>วิทยากรจากห้องเครื่องสำนักพระราชวัง</p>
                                            <p className='font-span'><strong>สถานที่เรียน : </strong>ตึกโรงอาหาร 2</p>
                                        </Col>
                                        <Col xl="6" className='px-5 mb-5'>
                                            <p className='font-span'><strong>ปิดรับสมัคร : </strong>30 มีนาคม 2565</p>
                                            <p className='font-span'><strong>อาหาร : </strong>รวมอาหาร</p>
                                            <p className='font-span'><strong>ที่พัก : </strong>ไม่รวม</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col style={{textAlign: "right"}}>
                                            <Button variant="" className='btn-enroll-course-close my-3' onClick={handleCloseEnrollCourse}>ปิดหน้าจอ</Button>
                                        </Col>
                                        <Col style={{textAlign: "left"}}>
                                            <Button variant="" className='btn-cancel my-3'>ยกเลิกการสมัคร</Button>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                            </Modal>

                            <Modal show={ShowQR} onHide={handleCloseQR} className="popup-qr-payment">
                                <Modal.Header className="modal-header-enroll">
                                    <h1 className='font-title'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</h1>
                                </Modal.Header>

                                <Modal.Body className='modal-body-enroll p-4'>
                                    <div className='text-center'>
                                        <img  src={QRPayment} alt="" className='mb-4' style={{backgroundColor: "#fff", width: "60%", height: "auto"}}/>
                                    </div>
                                    <p className='font-span mb-2'><strong>วันที่อบรม : </strong>4 พฤษภาคม 2565 (1 วัน)</p>
                                    <p className='font-span mb-2'><strong>เวลาอบรม : </strong>9:00 - 17:00 น.</p>
                                    <p className='text-confirm'><strong>สถานะ : ยืนยันแล้ว</strong></p>
                                    <div className='text-center'>
                                        <Button variant="" className='btn-enroll-course-close my-3' onClick={handleCloseQR}>ปิดหน้าจอ</Button>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default EnrollCourse