import { useState, useEffect } from "react";
import { useParams, useNavigate, redirect} from 'react-router-dom';
import { apiLink } from '../constants/DataPublic'

const useLogin = (props) => {

    let userLogin = {}

    const {id } = useParams();
    const [users, setUsers] = useState([]);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [showfalse, setShowfalse] = useState(false);
    const handleShowfalse = () => setShowfalse(true);
    const handleClosefalse = () => setShowfalse(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [forget, setForget] = useState('')
    const [newPass, setNewPass] = useState('')
    const [conPass, setConfirmPass] = useState('')

    const [message, setMessage,] = useState("");
    const navigate = useNavigate();

    const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

    const refreshPage = () =>{ 
        window.location.reload(); 
    }

    const Userlogin = (e) => {

      e.preventDefault();

      const body = JSON.stringify({
        "key": username,
        "password": password, 
      })

      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: body,
      };

      fetch (apiLink+'/member/login', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result["message"] === undefined){
          setMessage("เลขบัตรประชาชน/อีเมล์ และ/หรือ รหัสผ่านไม่ถูกต้อง หากจำรหัสผ่านไม่ได้ให้เปลี่ยนรหัสผ่านใหม่")
          if (result["result"] === true){
            setMessage("")
            userLogin = {
              "member_id": result["data"][0].member_id,
              "title": result["data"][0].title,
              "firstname": result["data"][0].firstname,
              "lastname": result["data"][0].lastname,
              "email": result["data"][0].email,
              "idcard": result["data"][0].idcard,
              "mobile": result["data"][0].mobile,
              "lineid": result["data"][0].lineid,
              "food_condition": result["data"][0].food_condition,
              "profile_image": result["data"][0].profile_image,
              "password": result["data"][0].password
            }
            // console.log(result["data"][0].member_id)
            sessionStorage.setItem('UserLogin',JSON.stringify(userLogin))
            navigate("/")
            refreshPage()

          }
        } else {
          console.log(result["message"])
          setMessage(result["message"])
        }
      })
      .catch((error) => {
         console.log("Error: ", error);
       });
    }

    const forgetPassword = (e) => {
      e.preventDefault();

      if (forget == '') {
        setMessage("กรุณาระบุรหัสผ่าน")
      } else {
        const body = JSON.stringify({
          "key": forget
        })
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: body,
        };
        fetch (apiLink+'/member/forgetPassword', requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result["result"] === true){
              console.log(result["message"])
              handleShow();
            } else {
              setMessage(result["message"])
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      }

    }

    const checkChangePass = () => {
      var requestOptions = {
          method: 'GET',
          headers: myHeaders,
      };

      fetch(apiLink+"/member/checkToken/"+id, requestOptions)
      .then(response => response.json())
      .then(result => {
          if (result["result"] == false) {
              console.log("checkUser", result["result"])
              handleShowfalse()
          } else {
              console.log("checkUser", result["result"])
          }
      })
  }

    const changPass = (e) => {
      e.preventDefault();

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };

      fetch(apiLink+"/member/checkToken/"+id, requestOptions)
        .then(response => response.json())
        .then(result => {

          if(result["result"] == false) {
            console.log("False", result["result"])
            handleShowfalse()
          } else {
            e.preventDefault();
            console.log("True", result["result"])

            setUsers(result["data"][0])
          // console.log(users)

            if (newPass == '' || conPass == '' ) {
              setMessage("กรุณาระบุรหัสผ่าน")
            } else if (newPass.length < 8 || conPass.length < 8) {
              setMessage("กรุณาระบุรหัสผ่านความยาวขั้นต่ำ 8 ตัวอักษร")
            } else if (newPass != conPass ) {
              setMessage("รหัสผ่านไม่ตรงกัน")
            } else {
              setMessage("")
              const body = JSON.stringify({
                "member_id": users["member_id"],
                "firstname": users["firstname"],
                "lastname": users["lastname"],
                "email": users["email"],
                "password": conPass
              })
              console.log("body =>", body)
              console.log(result["result"])

              try {
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: body,
                    };
              } catch (err) {
                console.log(err);
              }

              fetch (apiLink+'/member/changePassword', requestOptions)
                  .then(response => response.json())
                  .then(result => {
                      if (result["result"] === true){
                          console.log(result["message"])
                          handleShow();
                      } else {
                          console.log(result["message"])
                      }
                  })
                  .catch((error) => {
                      console.log("Error: ", error);
                  });
            } 

          }
        })
    }

    return {show, showfalse, userLogin, username, password, message, forget, newPass, conPass, handleClosefalse, refreshPage, setNewPass, setConfirmPass, setPassword, Userlogin, setUsername, setForget, forgetPassword, changPass, handleClose, checkChangePass }
}
export default useLogin