import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import banner from '../assets/images/Banner-2.png'
import search from '../assets/images/search.png'
import logo from '../assets/images/logo.jpg'

const Course = () => {

  const [Showcourse, setShowcourse] = useState(false);
  const handleCloseCourse = () => setShowcourse(false);
  const handleShowcourse = () => setShowcourse(true);

  const [ShowEnroll, setShowEnroll] = useState(false);
  const handleCloseEnroll = () => setShowEnroll(false);
  const handleShowEnroll = () => setShowEnroll(true);
  

  return (
    <div className="bg">
      <Container>
        {/* Banner */}
        {/* <Carousel>
              <Carousel.Item> 
                <Image src={banner} alt="" className='banner'/>
              </Carousel.Item>
              <Carousel.Item> 
                <Image src={banner} alt="" className='banner'/>
              </Carousel.Item>
            </Carousel> */}

        <Row className='justify-content-center'>
          <Col xs="11" md="12" lg="12" xl="12" className='py-5'>
            <h1 className='font-title mb-4 text-left'>หลักสูตร SMEs</h1>
            <Form>
              <div className="mb-3">
                <Form.Label className='px-3 course-lable'>แสดงหลักสูตร :</Form.Label>
                <Form.Check inline name="group1" type="radio" label="ทั้งหมด" className='lable-text'/>
                <Form.Check inline name="group1" type="radio" label="เฉพาะที่เปิดรับสมัคร" className='lable-text'/>
              </div>
            </Form>

            <Row className="mb-5">
              <Col xs="12" md="12" lg="8" xl="8" className='mb-3'>
                <Row>
                  <Col md="4" lg="5" xl="3" className='alignItems-center'>
                    <Form.Label className='px-3 course-lable'>สถานะหลักสูตร : </Form.Label>
                  </Col>
                  <Col md="6" lg="6" xl="5">
                    <Form>
                      <Form.Select aria-label="Default select example input-login" required>
                        <option>กรุณาเลือกสถานะ</option>
                          <option value="1">มีที่ว่าง</option>
                          <option value="2">ผู้สมัครเต็ม</option>
                          <option value="3">ทั้งหมด</option>
                      </Form.Select>
                    </Form>
                  </Col>
                </Row>
              </Col>

              <Col xs="12" md="12" lg="4" xl="4">
                <Row>
                <Col md="2" lg="3" xl="3" className='alignItems-center'>
                  <Form.Label className='px-3 course-lable'>ค้นหา</Form.Label>
                </Col>
                <Col md="8" lg="9" xl="9">
                  <Form className="d-flex">
                    <Form.Control type="search" placeholder="" className="me-2" aria-label="Search"/>
                    <Button variant=""><img src={search} alt=""/></Button>
                  </Form>
                </Col>
                </Row>
              </Col>
            </Row>


            <Row className='course-detail px-3 py-4 mb-4'>
              <Col md="12" lg="3" xl="3"><img src={banner} alt="" className='img-full mb-3'/></Col>
              <Col md="12" lg="7" xl="7" className='mb-3'>
                <h1 className='course-detail-title'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</h1>
                <span className='font-span'>โครงการลูกพระดาบสฯ จัดอบรมสอนการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส เมื่อวันที่ 4 พฤษภาคมที่ผ่านมา ณ โครงการลูกพระดาบสฯ ซึ่งได้รับความสนใจและความร่วมมือ เป็นอย่างดี </span>
                <Row className='pt-3'>
                  <Col lg="12" xl="5" className='course-text'><strong>วันที่อบรม :</strong>  4 พฤษภาคม 2565</Col>
                  <Col lg="12" xl="7" className='course-text'><strong>ปิดรับสมัคร :</strong>  30 มีนาคม 2565</Col>
                  <Col lg="12" xl="5" className='course-text'><strong>เวลาอบรม :</strong> 9:00 - 17:00 น. </Col>
                  <Col lg="12" xl="7" className='course-text'><strong>สถานที่อบรม : </strong>  ตึกโรงอาหาร 2</Col>
                </Row>
                
              </Col>
              <Col className='text-center' md="12" lg="2" xl="2">
                <p className='course-status mb-1'>มีที่ว่าง</p>
                <Button variant="" className='btn-course-status' onClick={handleShowcourse}>สมัครอบรม</Button>
              </Col>
            </Row>

            <Row className='course-detail px-3 py-4 mb-4'>
              <Col md="12" lg="3" xl="3"><img src={banner} alt="" className='img-full mb-3'/></Col>
              <Col md="12" lg="7" xl="7" className='mb-3'>
                <h1 className='course-detail-title'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</h1>
                <span className='font-span'>โครงการลูกพระดาบสฯ จัดอบรมสอนการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส เมื่อวันที่ 4 พฤษภาคมที่ผ่านมา ณ โครงการลูกพระดาบสฯ ซึ่งได้รับความสนใจและความร่วมมือ เป็นอย่างดี </span>
                <Row className='pt-3'>
                  <Col lg="12" xl="5" className='course-text'><strong>วันที่อบรม :</strong>  4 พฤษภาคม 2565</Col>
                  <Col lg="12" xl="7" className='course-text'><strong>ปิดรับสมัคร :</strong>  30 มีนาคม 2565</Col>
                  <Col lg="12" xl="5" className='course-text'><strong>เวลาอบรม :</strong> 9:00 - 17:00 น. </Col>
                  <Col lg="12" xl="7" className='course-text'><strong>สถานที่อบรม : </strong>  ตึกโรงอาหาร 2</Col>
                </Row>
                
              </Col>
              <Col className='text-center' md="12" lg="2" xl="2">
                <p className='course-status mb-1'>มีที่ว่าง</p>
                <Button variant="" className='btn-course-status' onClick={handleShowcourse}>สมัครอบรม</Button>
              </Col>

              <Modal show={Showcourse} onHide={handleCloseCourse} className="course-register">
                <Modal.Body className='text-center text-popup px-05'>
                  <img src={logo} alt="" className='logo-popup my-5' />
                  <div className='mb-4'>
                    <p className='title-popup-course'>โรงเรียนลูกพระดาบส</p>
                    <span className='title-popup-course-en'>LUKPHRADABOS</span>
                  </div>
                  <p className="popup-course-desc mb-5">การลงทะเบียนหลักสูตร SMEs ท่านสามารถลงทะเบียนสมัครได้ปีละ 1 หลักสูตรเท่านั้น</p>
                  <Form.Check type="checkbox" label="รับทราบเงื่อนไข" id="" className='text-checkbox justifyCenter'/>
                  <Button variant="" className='btn-popup-course my-5' onFocus={handleCloseCourse} onClick={handleShowEnroll}>ตกลง</Button>
                </Modal.Body>
              </Modal>

              <Modal show={ShowEnroll} className="course-enroll">
                <Modal.Header className="modal-header-enroll">
                  <Col xl="2" className="p-2">
                    <img src={banner} alt="" className='img-full' />
                  </Col>
                  <Col xl="10" className="p-2">
                    <h1 className='font-title'>อบรมการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส</h1>
                  </Col>
                </Modal.Header>
                <Modal.Body className='modal-body-enroll p-4'>
                  <Row>
                    <Col xl="5" className='mb-5'>
                      <Row>
                        <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                        <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                        <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                        <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                        <Col xl="4" className='mb-3'><img src={banner} alt="" className='img-full' /></Col>
                        <Col xl="4" className='mb-3'><img  src={banner} alt="" className='img-full' /></Col>
                      </Row>
                    </Col>
                    <Col xl="7" className='mb-5'>
                      <p className='font-span'>โครงการลูกพระดาบสฯ จัดอบรมสอนการทำคานาเป้กุ้ง กุ้งพล่า และกุ้งผัดซอส เมื่อวันที่ 4 พฤษภาคมที่ผ่านมา ณ โครงการลูกพระดาบสฯ ซึ่งได้รับความสนใจและความร่วมมือเป็นอย่างดี</p>
                    </Col>

                    <Col xl="6" className='px-5 mb-5'>
                      <p className='font-span'><strong>วันที่อบรม : </strong>4 พฤษภาคม 2565 (1 วัน)</p>
                      <p className='font-span'><strong>เวลาอบรม : </strong>9:00 - 17:00 น.</p>
                      <p className='font-span'><strong>วิทยากร : </strong>วิทยากรจากห้องเครื่องสำนักพระราชวัง</p>
                      <p className='font-span'><strong>สถานที่เรียน : </strong>ตึกโรงอาหาร 2</p>
                    </Col>
                    <Col xl="6" className='px-5 mb-5'>
                    <p className='font-span'><strong>ปิดรับสมัคร : </strong>30 มีนาคม 2565</p>
                      <p className='font-span'><strong>อาหาร : </strong>รวมอาหาร</p>
                      <p className='font-span'><strong>ที่พัก : </strong>ไม่รวม</p>
                    </Col>

                    <Col xl="12">
                      <div className='modal-body-enroll-detail p-3'>
                        <h1 className='course-detail-title text-center mb-4'>ตรวจสอบข้อมูลผู้สมัคร</h1>
                        <Row className='mb-3'>
                          <Col xl="5">
                            <p className='font-span mb-0'><strong>ชื่อ-นามสกุล : </strong>นางสาวมาดี มีนาคม</p>
                            <p className='font-span mb-0'><strong>อีเมล์ : </strong>madee@gmail.com</p>
                            <p className='font-span mb-0'><strong>เลขบัตรประจำตัวประชาชน : </strong>1100992776611</p>
                            <p className='font-span mb-0'><strong>เบอร์โทรติดต่อ :</strong> 091-1991111</p>
                            <p className='font-span mb-0'><strong>ไลน์ไอดี : </strong>madee611</p>
                            <Button variant="" className='mt-3 enroll-edit'>แก้ไขข้อมูล</Button>
                          </Col>
                          <Col xl="7"  className='mt-4'>
                            <Row className='font-span'>
                              <Col xl="5" className='text-right'><strong>ข้อจำกัดด้านอาหาร :</strong></Col>
                              <Col xl="7">
                              <Form>
                                <Form.Check type="radio" name="group1" label="ไม่มี" id=""/>
                                <Form.Check type="radio" name="group1" label="รับประทานอาหารอิสลามเท่านั้น" id=""/>
                                <Form.Check type="radio" name="group1" label="รับประทานอาหารมังสวิรัติเท่านั้น" id=""/>
                                <Form.Check type="radio" name="group1" label="รับประทานอาหารเจเท่านั้น" id=""/>
                                <Form.Check type="radio" name="group1" label="ไม่รับประทานเนื้อไก่" id=""/>
                              </Form>
                              </Col>
                            </Row>
                            <Row className='font-span'>
                              <Col xl="5" className='text-right'><strong className='text-right'>ต้องการที่พัก :</strong></Col>
                              <Col xl="7">
                              <Form>
                                <Form.Check inline label="ไม่ต้องการ" name="group2" type="radio" id=""/>
                                <Form.Check inline label="ต้องการ" name="group2" type="radio" id=""/>
                              </Form>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Form.Check type="checkbox" label="หลังการสมัครอบรมแล้ว ทางเจ้าหน้าที่จะโทรติดต่อกลับ เพื่อให้ผู้อบรมทราบว่าท่านมีสิทธิ์ได้เข้าเรียนหลักสูตรนี้ โดยใช้เบอร์ที่ติดต่อ 086-4583507" id="" className='text-checkbox' style={{textAlign: "left"}}/>
                        <Row>
                          <Col style={{textAlign: "right"}}>
                            <Button variant="" className='btn-regis-popup my-3'>
                              สมัครอบรม
                            </Button>
                          </Col>
                          <Col style={{textAlign: "left"}}>
                            <Button variant="" className='btn-regis-close my-3' onClick={handleCloseEnroll}>ปิดหน้าจอ</Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                  </Row>
                  
                </Modal.Body>
                 
              </Modal>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Course