import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import './styles/mystyles.css'
import 'bootstrap/dist/css/bootstrap.min.css';


import Homepage from './page/home';
import NavScroll from './componant/header';
import Footer from './componant/footer';
import History from "./page/history";
import Philosophy from "./page/philosophy";
import Result from "./page/result";
import Products from "./page/products";
import ProductDetail from "./page/pdetail";
import Contact from "./page/contact";
import Login from "./page/login";
import Register from "./page/register";
import Test from "./page/test";
import Password from "./page/password";
import Forget from "./page/forget";
import ChangePass from "./page/changepass";
import PageNotFound from "./page/page404";
import Profile from "./page/profile";
import EnrollCourse from "./page/enrollcourse";
import Course from "./page/course";
import Objective from "./page/objective";

function App() {
  return (
    <Router>
      <NavScroll/>
        <Routes>
          <Route path="/" element={<Homepage/>}/>
          <Route path="/history" element={<History/>}/>
          <Route path="/philosophy" element={<Philosophy/>}/>
          <Route path="/result" element={<Result/>}/>
          <Route path="/product" element={<Products/>}/>
          <Route path="/pdetail" element={<ProductDetail/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/password/:id" element={<Password/>}/>
          <Route path="/forget" element={<Forget/>}/>
          <Route path="/changepass/:id" element={<ChangePass/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/enrollcourse" element={<EnrollCourse/>} />
          <Route path="/course" element={<Course/>}/>
          <Route path="/404" element={<PageNotFound/>}/>
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="objective" element={<Objective/>}/>

          <Route path="/test" element={<Test/>}/>
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
