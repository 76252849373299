import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const ProductDetail = () => {


  return (
    <div className="bg">
        <Container className='py-5'>
            <Row>
                <h1 className='font-title'>ผลิตภัณฑ์โครงการ</h1>
                <Col xs="12" md="12" lg="5" xl="5">
                  
                
                  
                </Col>
                <Col xs="12" md="12" lg="7" xl="7">
                    <h1 className='detail-title'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <p className='detail-title my-4'>ราคา 120 บาท</p>

                    <div className='mb-4'>
                        <p className='font-span mb-0'><strong>รายละเอียด</strong></p>
                        <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    </div>

                    <div>
                        <p className='font-span mb-0'><strong>ช่องทางการจำหน่าย</strong></p>
                        <ol className='font-span'>
                            <li>โรงเรียนลูกพระดาบส อาศรมการเพาะเห็ด</li>
                            <li>ร้านมูลนิธิพระดาบส (ภายในโรงพยาบาลรามาธิบดีจักรีนฤบดินทร์ หน้า 7-11) ****หมายเหตุ ราคาหน้าร้าน เห็ดหลินจือ อบแห้ง 140 บาท/ สปอร์เห็ดหลินจือ ชนิดแคปซูล 350 บาท</li>
                            <li>สั่งซื้อเพื่อให้จัดส่ง เบอร์โทร 087 - 7745185 (คุณจ๋า หัวหน้างานเพาะเห็ด โทรตามวันเวลาราชการ)</li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </Container>

         </div>

  )
}
export  default ProductDetail