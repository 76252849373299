import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

import useRegister from '../customHook/useRegister';
import { apiLink } from '../constants/DataPublic';

import Camera from '../assets/images/camera.png'
import Close from '../assets/images/closed.png'

const Register = (props) => {

    let {title, firstname, lastname, email, idcard, mobile, lineid, setTitle, setFirstname, setLastname, setEmail, setIdcard, setMobile, setLineid, successShow, failedShow, registerText, base64Data, titlemessage, messageFName, messageLName, messageemail, messageidcard, messagemobile, saveRegis, selectmage, successClose, failedClose } = useRegister();

    const [titles, setTitles] = useState([]);

    useEffect(() => {
        getApiTitle();
    }, []);

    const navigate = useNavigate();

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(()=>{
        if (user != null){
        navigate("/")
        }
    },[])

    
    const getApiTitle = async () => {
        try {
            const requestOption = {
                "method": "GET"
            }
            const response = await fetch(
                apiLink+"/member/showTitle" , requestOption).then((response) => response.json());
            setTitles(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="bg">
            <Container>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='white-box my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>กรอกข้อมูลลงทะเบียน</h1>
                        <Col className='margin-auto mb-5'>
                            <input type="file" name='image64' accept="image/*" onChange={selectmage} id="select-image" style={{display: 'none'}}/>
                            <div className="box-profile">
                                {base64Data !== null && <img src={base64Data} className="img-profile" alt="Uploaded" />}
                            </div>
                            <div className='box-camera'>
                                <label htmlFor="select-image" className='select-img'>
                                    <img src={Camera} alt="" width={20}/>
                                </label>
                            </div>
                        </Col>

                        <Form className='px-05'>
                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>คำนำหน้า *</Form.Label>
                                <Form.Select aria-label="Default select example input-login" name="title" value={title} onChange={e => setTitle(e.target.value)} required>
                                    <option key='001' value="001">กรุณาเลือกคำนำหน้า</option>
                                    {titles.map((data)=>{
                                        return <option key={data.title_id} value={data.title_name}>{data.title_name}</option> 
                                    })}
                                </Form.Select>
                                <p className='my-2 message px-2'>{titlemessage}</p> 
                            </Form.Group>
                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>ชื่อ *</Form.Label>
                                <Form.Control type="text" placeholder="" className='input-login' name="firstname" value={firstname} onChange={e => setFirstname(e.target.value)} required/>
                                <p className='my-2 message px-2'>{messageFName}</p>
                            </Form.Group>
                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>นามสกุล *</Form.Label>
                                <Form.Control type="text" placeholder="" className='input-login' name="lastname" value={lastname} onChange={e => setLastname(e.target.value)} required/>
                                <p className='my-2 message px-2'>{messageLName}</p>
                            </Form.Group>
                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>อีเมล์ *</Form.Label>
                                <Form.Control type="email" placeholder="" className='input-login' name="email" value={email} onChange={e => setEmail(e.target.value)} required/>
                                <p className='my-2 message px-2'>{messageemail}</p>
                            </Form.Group>
                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>เลขบัตรประจำตัวประชาชน *</Form.Label>
                                <Form.Control type="text" maxLength={13} placeholder="" className='input-login' name="idcard" value={idcard} onChange={e => setIdcard(e.target.value)} required onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    }}
                                />
                                <p className='my-2 message px-2'>{messageidcard}</p>
                            </Form.Group>
                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>เบอร์โทรศัพท์ *</Form.Label>
                                <Form.Control type="text" maxLength={10} placeholder="" className='input-login' name="mobile" value={mobile} onChange={e => setMobile(e.target.value)} required onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                    }}
                                />
                                <p className='my-2 message px-2'>{messagemobile}</p>
                            </Form.Group>
                            <Form.Group className="mb-4 text-left">
                                <Form.Label className='px-3 regis-lable'>ไลน์ไอดี</Form.Label>
                                <Form.Control type="text" placeholder="" className='input-login' name="lineid" value={lineid} onChange={e => setLineid(e.target.value)}/>
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type='submit' size="lg" className='btn-login font-btn' onClick={saveRegis}>ลงทะเบียน</Button>
                            </div>
                        </Form>

                        <Modal show={successShow} onHide={successClose} className="register">
                            <Modal.Header style={{borderBottom: 0, justifyContent: "end"}}>
                                <Link to="/"><img src={Close}/></Link>
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <div>{registerText}</div>
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>
                        
                        <Modal show={failedShow} onHide={failedClose} className="register">
                            <Modal.Header closeButton>
                                {/* <img src={Close}/> */}
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <div>{registerText}</div>
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Register