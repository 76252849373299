import React,  { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "react-router-dom";
import { apiLink } from '../../constants/DataPublic';

const Course = () => {

    const [courses, setCourses] = useState([]);
    const [isLoading, setLoading] = useState(false); 

    useEffect(() => {
        setLoading(true);
        getApiCourse();
    }, []);

    if (isLoading) return <p> Loading...</p>
    if (!courses) return <p> No data </p>

    const getApiCourse = async () => {
        try {
            const requestOption = {
                "method": "GET"
            }
        
            const response = await fetch(
            apiLink+"/home/course" , requestOption).then((response) => response.json());
            setCourses(response.data);
            setLoading(false);
            // console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Col xs="12" md="12" lg="12" xl="12" className='box-course py-5'>
            <h1 className='font-title mb-4'>หลักสูตรที่เปิดสอน</h1>
            <Row className='justifycontent-space-evenly'>
                {courses.map((data)=>{
                    return ( 
                        <Col key={data.type_name} xs="10" md="11" lg="5" xl="5" className='box-course-desc mb-4'>
                            <Row className='align-items-center'>           
                                <Col xs="12" md="6" lg="6" xl="6" style={{ padding: 0 }}>
                                    <img src={data.type_image} alt="" className='course-img' />
                                </Col>
                                <Col xs="12" md="6" lg="6" xl="6" style={{ padding: 20 }}>
                                    <h1 className='course-title'>{data.type_name}</h1>
                                    <div className='font-span'>
                                        {data.type_description}
                                    </div>
                                    <Link to="/" style={{float: "right"}}>ดูหลักสูตรเพิ่มเติม ...</Link>
                                </Col>
                            </Row>
                        </Col>
                    )
                })}
            </Row>
        </Col>
    )
}
export default Course