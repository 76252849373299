const apiLink = 'https://api.lukphradabos.com'

// AppId -> สำหรับ Sign in ด้วย Facebook Account
const appId = "757978879468658"

// AppId -> สำหรับ Sign in ด้วย Google
const clientId = "864528515538-jp4ofbr9efsfifdtb0v7impinvcu6rpd.apps.googleusercontent.com"

const baseLink = ''

export {apiLink, appId, clientId, baseLink}