import React, { useState, useEffect } from 'react';

const Test = (base64Data) => {
  const imageUrl = `data:image/png;base64, ${base64Data}`;

  return (
    <div>
      <img src={imageUrl} alt="Base64 Image" />
    </div>
  );
};

export default Test;
