import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import search from '../assets/images/search.png'
import banner from '../assets/images/Banner-2.png'



const Products = () => {
  return (
    <div className="bg">
      <Container className='py-5'>
        <Row>
            <Col xl="12" className='mb-5'>
                <Row>
                    <Col xl="7">
                        <h1 className='font-title'>ผลิตภัณฑ์โครงการ</h1>
                    </Col>
                    <Col xl="5">
                        <Row>
                            <Col md="2" lg="3" xl="3" className='alignItems-center'>
                                <Form.Label className='px-3 course-lable'>ค้นหา</Form.Label>
                            </Col>
                            <Col md="8" lg="9" xl="9">
                                <Form className="d-flex">
                                    <Form.Control type="search" placeholder="" className="me-2" aria-label="Search"/>
                                    <Button variant=""><img src={search} alt=""/></Button>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>
            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>
            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>

            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>
            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>
            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>

            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>
            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>
            <Col xs="12" md="6" lg="6" xl="4" className='mb-4'>
                <div className='box-product p-4'>
                    <img src={banner} alt="" className='img-full mb-3'/>
                    <h1 className='detail-title mb-0'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</h1>
                    <span className='font-span'>เห็ดหลินจือ อบแห้ง ขนาด 50 กรัม</span>
                    <p className='p-price text-right mt-4'>ราคา 120 บาท</p>
                    <Button href='/pdetail' variant="" className='btn-yellow width-full'><span className='font-btn-yellow'>ดูรายละเอียด</span></Button>
                </div>
            </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Products