import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Banner from '../componant/home/banner';
import Course from '../componant/home/course';
import Products from '../componant/home/products';

const Homepage = () => {

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    return ( 
        <div className="bg">
            <Container style={{ backgroundColor: "#5a3f2b42"}}>
                <Row className='text-center'> 
                    {/* Banner */}
                        <Banner/>
                    {/* หลักสูตรที่เปิดสอน */}
                        <Course/>
                    {/* ผลิตภัณฑ์โครงการ */}
                        {/* <Products/>  */}
                </Row>
            </Container>
        </div>
    )
    
}
export default Homepage