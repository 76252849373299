import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import useLogin from '../customHook/userLogin';

import Close from '../assets/images/closed.png'

export const ChangePass = (props) => {
    
    const navigate = useNavigate;

    let {show, showfalse, message, newPass, conPass, changPass, handleClosefalse, setNewPass, setConfirmPass, handleClose, checkChangePass } = useLogin();

    useEffect(() => {
        checkChangePass();
    }, []);
    

    return (
        <div className="bg">
            <Container style={{height: "100vh", display: "grid", alignItems: "center"}}>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='white-box my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>ยืนยันและกรอกรหัสผ่าน</h1>
                        <Form className='px-05'>
                            <Form.Group className="mb-3 text-left">
                                <Form.Control type="password" placeholder="รหัสผ่านใหม่" className='text-center input-login mb-3' minLength={8} value={newPass} onChange={e => setNewPass(e.target.value)} required/>
                                <Form.Control type="password" placeholder="ยืนยันรหัสผ่านใหม่" className='text-center input-login mb-3' minLength={8} value={conPass} onChange={e => setConfirmPass(e.target.value)} required/>
                                <p className='my-2 message px-2'>{message}</p> 
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button onClick={changPass} variant="primary" size="lg" className='btn-login font-btn'>เปลี่ยนรหัสผ่าน</Button>
                            </div>
                        </Form>

                        <Modal show={show} onHide={handleClose} className="register">
                            <Modal.Header style={{borderBottom: 0, justifyContent: "end"}}>
                                <Link to="/login" ><img src={Close}/></Link>
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <p>เปลี่ยนรหัสผ่านสำเร็จ</p>
                                    <Button href="/login" type='submit' variant="primary" size="lg" className='btn-login font-btn' style={{width: "40%"}}>เข้าสู่ระบบ</Button>
                                
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>

                        <Modal show={showfalse} onHide={handleClosefalse} className="register">
                            <Modal.Header style={{borderBottom: 0, justifyContent: "end"}}>
                                <Link to="/forget" ><img src={Close}/></Link>
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <p>ลิ้งค์ของคุณหมดอายุแล้ว กรุณาแจ้งลืมรหัสผ่านใหม่อีกครั้ง</p>
                                <Button href="/forget" type='submit' variant="primary" size="lg" className='btn-login font-btn' style={{width: "40%"}}>ลืมรหัสผ่าน</Button>
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default ChangePass