import FB from '../assets/images/FB.png';
import YT from '../assets/images/youtube.png';
import IG from '../assets/images/IG.png';
import TW from '../assets/images/TW.png';
import mail from '../assets/images/mail.png';

const Footer = () => {
  return (
    <div className='boxfooter text-center'>
        <div style={{paddingBottom: "8px"}}>
            <span style={{paddingRight: "8px"}}><img src={FB} width={24} /></span>
            <span style={{paddingRight: "8px"}}><img src={YT} width={24} /></span>
            <span style={{paddingRight: "8px"}}><img src={IG} width={24} /></span>
            <span style={{paddingRight: "8px"}}><img src={TW} width={24} /></span>
            <span><img src={mail} width={24}/></span>
        </div>
        <div className='text-white'>@ 2023 Lukphradabos. All Rights Reserved.</div>
    </div>
  )
}
export default Footer
