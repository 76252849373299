import Container from 'react-bootstrap/Container';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import logo from '../assets/images/logo.jpg' 
import bar from '../assets/images/more.png' 
import Close from '../assets/images/closed.png'

import { useNavigate, Link } from "react-router-dom";
import { baseLink } from "../constants/DataPublic";
import { Button } from 'react-bootstrap';

import useLogin from '../customHook/userLogin';


const NavScroll = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let { refreshPage } = useLogin();

  const logOut = () => {
    
    if (user != null) {
      
      sessionStorage.removeItem('UserLogin')
      refreshPage()
    }
  }

  return (

    <>  
      <Container className='navmobile'>
        <Row>
          <Col xxl="6" xl="6" lg="6" md="7" sm="9" xs="9">
            <Row style={{alignItems: "center"}}>
              <Col xxl="2" xl="2" lg="2" md="3" sm="3" xs="3" className='align-items-center p-0 m-0'>
                <img src={logo} alt="" width={80} height={80}/>
              </Col>
              <Col xxl="10" xl="10" lg="10" md="9" sm="9" xs="9" style={{padding: "10px"}}>
                  <p className='navbartitle mb-0'>โรงเรียนลูกพระดาบส</p>
                  <span className='navbardesc'>LUKPHRADABOS</span>
              </Col>
            </Row>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="5" sm="3" xs="3" className='align-items-center'>
            <Col variant="primary" onClick={handleShow} className='navposition pointer'>
              <img src={bar} className='navicon' />
            </Col>

            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton style={{paddingLeft: "30px"}}>
                <Offcanvas.Title>
                  <img src={logo} alt="" width={100} height={100}/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav>
                  <NavDropdown className='mobile' title="เกี่ยวกับโครงการ" id="#">
                    <NavDropdown.Item href="/history">ประวัติความเป็นมา</NavDropdown.Item>
                    <NavDropdown.Item href="/philosophy">ปรัชญา/วิสัยทัศน์/พันธกิจ</NavDropdown.Item>
                    <NavDropdown.Item href="/result">ผลการดำเนินงาน</NavDropdown.Item>
                    <NavDropdown.Item href="/objective">วัตถุประสงค์/เป้าหมาย</NavDropdown.Item>
                    {/* <NavDropdown.Item href="#">รางวัลและความสำเร็จ</NavDropdown.Item>
                    <NavDropdown.Item href="#">คณะกรรมการโครงการ</NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown className='mobile' title="หลักสูตรที่เปิดสอน" id="navbarScrollingDropdown">
                    <NavDropdown.Item href="/course">หลักสูตร SMEs</NavDropdown.Item>
                    <NavDropdown.Item href="/course">หลักสูตรระยะสั้น</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#" className='navlink mobile'>ผลิตภัณฑ์โครงการ</Nav.Link>
                  <Nav.Link href="/contact" className='navlink mobile'>ติดต่อโครงการ</Nav.Link>

                  { user ?  (
                    // <Nav.Link className='btnbrown mobile' href={toLink} onClick={logOut}>ออกจากระบบ</Nav.Link>
                    // <Nav.Link className='btnbrown' href="/profile">ข้อมูลสมาชิก</Nav.Link>
                    <NavDropdown className='mobile' title="ข้อมูลสมาชิก" id="">
                      <NavDropdown.Item href="/profile">ข้อมูลสมาชิก</NavDropdown.Item>
                      <NavDropdown.Item onClick={logOut}>ออกจากระบบ</NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <Nav.Link className='btnlogin mobile' href="/login">เข้าสู่ระบบ</Nav.Link>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
        </Row>
      </Container>

        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className='navdesktop'>
          <Container>
            <Navbar.Brand href="/">
              <Row>
                <Col>
                  <img src={logo} alt="" width={60} height={60}/>
                </Col>
                <Col style={{padding: "10px 0"}}>
                  <p className='navbartitle'>โรงเรียนลูกพระดาบส</p>
                  <span className='navbardesc'>LUKPHRADABOS</span>
                </Col>
              </Row>
            </Navbar.Brand>
            
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav>
                <NavDropdown title="เกี่ยวกับโครงการ" id="#">
                  <NavDropdown.Item href="/history">ประวัติความเป็นมา</NavDropdown.Item>
                  <NavDropdown.Item href="/philosophy">ปรัชญา/วิสัยทัศน์/พันธกิจ</NavDropdown.Item>
                  <NavDropdown.Item href="/result">ผลการดำเนินงาน</NavDropdown.Item>
                  <NavDropdown.Item href="/objective">วัตถุประสงค์/เป้าหมาย</NavDropdown.Item>
                  {/* <NavDropdown.Item href="#">รางวัลและความสำเร็จ</NavDropdown.Item>
                  <NavDropdown.Item href="#">คณะกรรมการโครงการ</NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown title="หลักสูตรที่เปิดสอน" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="/course">หลักสูตร SMEs</NavDropdown.Item>
                  <NavDropdown.Item href="/course">หลักสูตรระยะสั้น</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#" className='navlink'>ผลิตภัณฑ์โครงการ</Nav.Link>
                <Nav.Link href="/contact" className='navlink'>ติดต่อโครงการ</Nav.Link>

                { user ?  (
                  // <Nav.Link className='btnbrown' href={toLink} onClick={logOut}>ออกจากระบบ</Nav.Link>
                  // <Nav.Link className='btnbrown' href="/profile">ข้อมูลสมาชิก</Nav.Link>
                  <NavDropdown className='btnbrown' title="ข้อมูลสมาชิก" id="">
                    <NavDropdown.Item href="/profile">ข้อมูลสมาชิก</NavDropdown.Item>
                    <NavDropdown.Item onClick={logOut}>ออกจากระบบ</NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <Nav.Link className='btnlogin' href="/login">เข้าสู่ระบบ</Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    
    </>
  );
}

export default NavScroll;