import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

import Pic1 from '../assets/images/pic1.jpg'
import Pic2 from '../assets/images/pic2.jpg'
import Pic3 from '../assets/images/pic3.jpg'
import Pic4 from '../assets/images/pic4.jpg'
import Pic5 from '../assets/images/pic5.jpg'
import Pic6 from '../assets/images/pic6.jpg'
import Pic7 from '../assets/images/pic7.jpg'
import Pic8 from '../assets/images/pic8.jpg'

const Result = () => {
    return (
        <div className="bg">
            <Container>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='box-content my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>ผลการดำเนินงาน</h1>
                        <Accordion defaultActiveKey={['0']} flush className='px-3'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>จัดการเรียนการสอนศิษย์พระดาบส</Accordion.Header>
                                <Accordion.Body className='text-left font-span'>
                                    <div><strong style={{ paddingLeft: 35 }}>๑. หลักสูตรการเกษตรพอเพียง</strong> รับผิดชอบการจัดการเรียนการสอนแก่ศิษย์พระดาบสหลักสูตรเกษตรพอเพียง ตั้งแต่ปีการศึกษา ๒๕๔๕ ผลิตศิษย์พระดาบสรุ่นละ ๒๐ คน ได้รับการสนับสนุนจากมหาวิทยาลัยเกษตรศาสตร์</div>
                                    <div><strong style={{ paddingLeft: 35 }}>๒. หลักสูตรช่างไม้เครื่องเรือน</strong> รับผิดชอบการจัดการเรียนการสอนศิษย์พระดาบสหลักสูตรช่างไม้เครื่องเรือน ตั้งแต่ปีการศึกษา ๒๕๔๙ ผลิตศิษย์พระดาบสรุ่นละ ๑๐ คน ได้รับการสนับสนุนทางวิชาการจากสถาบันพัฒนาฝีมือแรงงานภาค ๑ สมุทรปราการ</div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>กิจกรรมการเกษตร</Accordion.Header>
                                <Accordion.Body className='text-left font-span'>
                                    <Row>
                                        <Col lg="6"><img src={Pic1} alt="" className='img-full my-3'/></Col>
                                        <Col lg="6"><img src={Pic2} alt="" className='img-full my-3'/></Col>
                                    </Row>
                                <div>
                                    <strong style={{paddingLeft: 35}}>๑. การประมง</strong>
                                    เนื่องจากสภาพดินและน้ำเหมาะกับการเลี้ยงปลาและกุ้ง ในสภาพความเค็มต่ำ จึงได้ดำเนินการเลี้ยงปลาและกุ้ง ดังนี้<br/>
                                        <strong style={{paddingLeft: 65}}>๑.๑. การเลี้ยงกุ้ง </strong>
                                        ได้เลี้ยงกุ้งขาวในระบบปิด จำนวน ๖ บ่อ บนพื้นที่ ๒๒.๕ ไร่ สร้างรายได้หลักให้โครงการฯ โดยมี บริษัท เจริญโภคภัณฑ์ จำกัด เป็นที่ปรึกษา<br/>
                                        <strong style={{paddingLeft: 65}}>๑.๒. การเลี้ยงปลาสลิด </strong>
                                        ได้เลี้ยงปลาสลิดโดยวิธีธรรมชาติ บนพื้นที่ ๒๐ ไร่ และร่วมมือกับเกษตรกรแปรรูปปลาสลิด เป็นแลาสลิดหอมทองจำหน่าย<br/>
                                        <strong style={{paddingLeft: 65}}>๑.๓. การเลี้ยงปลานิลในบ่อดินและกระชัง </strong>
                                        ได้เลี้ยงปลานิลพันธุ์จิตรลดา ๓ ในบ่อดิน จำนวน ๗ บ่อ ๓๗ ไร่ และเลี้ยงปลานิลในกระชัง จำนวน ๔๐ กระชัง<br/>
                                        <strong style={{paddingLeft: 65}}>๑.๔. การเพาะขยายพันธุ์ปลา </strong>
                                        ได้ผลิตลูกปลานิลแปลงเพศ จำหน่ายให้แก่เกษตรกรในเขตจังหวัดสมุทรปราการ และพื้นที่ใกล้เคียง ในราคาย่อมเยา<br/>
                                        <strong style={{paddingLeft: 65}}>๑.๕. การเลี้ยงปลาดุก </strong>
                                        ได้เลี้ยงและขยายพันธุ์ปลาดุกอุย<br/>
                                        <strong style={{paddingLeft: 65}}>๑.๖. การเลี้ยงกบ </strong>
                                        ได้ทดลองเลี้ยงและขยายพันธุ์กบ พันธุ์บลูฟร็อกซ์ และพันธ์พื้นเมือง<br/>
                                </div>
                                <Row>
                                        <Col lg="6"><img src={Pic3} alt="" className='img-full my-3'/></Col>
                                        <Col lg="6"><img src={Pic4} alt="" className='img-full my-3'/></Col>
                                </Row>
                                <div>
                                    <strong style={{paddingLeft: 35}}>๒. การปลูกผักและผลไม้</strong><br/>
                                        <strong style={{paddingLeft: 65}}>๒.๑. การปลูกผักไฮโดรพอนิกส์ </strong>
                                        ได้ปลูกผักสลัดจำนวน ๖ ชนิด บนแปลงปลูกกลางแจ้ง จำนวน ๑๐๖ แปลง และโรงเรือนแบบปิด บนพื้นที่ ๑.๕ ไร่<br/>
                                        <strong style={{paddingLeft: 65}}>๒.๒. การปลูกผักบนดิน </strong>
                                        มีการปลูกผักสาธิตในถังพลาสติก และถุงพลาสติก พี.อี. เพื่อเป็นการฝึกปฏิบัติงานของศิษย์พระดาบสหลักสูตรการเกษตรพอเพียง และมีการบริการจำหน่ายพันธุ์ผักสวนครัวด้วย<br/>
                                        <strong style={{paddingLeft: 65}}>๒.๓. การปลูกมะม่วง </strong>
                                        ได้ปลูกมะม่วงพันธุ์น้ำดอกไม้ เบอร์ ๔ ในวงซีเมนต์ ๕ แปลง พันธุ์น้ำดอกไม้สีทอง ๔ แปลง และพันธุ์มะม่วงโชคอนันต์ ๑ แปลง<br/>
                                        <strong style={{paddingLeft: 65}}>๒.๔. การปลูกแก้วมังกร </strong>
                                        ได้ปลูแก้วมังกรพันธ์สีขาว สีแดง และสีเหลือง จำนวน ๔.๕ แปลง<br/>
                                        <strong style={{paddingLeft: 65}}>๒.๕. การปลูกผลไม้อื่นๆ </strong>
                                        ได้ปลูกกระท้อนพันธุ์ปุยฝ้าย และชมพู่พันธุ์เพชรสายรุ้ง มะพร้าว และกล้วยน้ำว้า อย่างละ ๑ แปลง<br/>
                                        <strong style={{paddingLeft: 65}}>๒.๖. การปลูกไผ่พันธุ์ต่างๆ </strong>
                                        มีการทดลองปลูกพันธุ์ไผ่ชนิดต่างๆ จำนวน ๓ แปลง<br/>
                                        <strong style={{paddingLeft: 65}}>๒.๗. การปลูกมะนาว</strong>
                                        ได้ปลูกมะนาวพันธุ์ตาฮิติในวงซีเมนต์ เพื่อบังคับให้ออกดอกนอกฤดู<br/>
                                        <strong style={{paddingLeft: 65}}>๒.๘. การเพาะเห็ด </strong>
                                        ได้เพาะเห็ดพันธุ์ฮังการี ภูฏาน ยานางิ ขอนขาว และเป๋าฮื้อ จำนวน ๓ โรงเรือน และได้ผลิดก้อนเชื้อเห็ดจำหน่ายด้วย<br/>
                                </div>
                                <Row>
                                        <Col lg="6"><img src={Pic5} alt="" className='img-full my-3'/></Col>
                                        <Col lg="6"><img src={Pic6} alt="" className='img-full my-3'/></Col>
                                </Row>
                                <div>
                                    <strong style={{paddingLeft: 35}}>๓. การปลูกไม้ทนสภาพดินเค็ม </strong>
                                    ได้ปลูกมะขามเปรี้ยว มะขามเทศ กระถิน เทพา สะเดา ขี้เหล็ก ยูคาลิปตัส โพธิ์ทะเล มะฮอกกานี ฯลฯ รอบพื้นที่โครงการฯ เพื่อความร่มรื่น และนำเนื้อไม้มาใช้ประโยชน์ในหลักสูตรช่างไม้เครื่องเรือน
                                </div>
                                <div>
                                    <strong style={{paddingLeft: 35}}>๔. การปลูกดอกไม้ </strong><br/>
                                        <strong style={{paddingLeft: 65}}>๔.๑. การปลูกกล้วยไม้ </strong><br/>
                                        ได้ปลูกกล้วยไม้ตระกูลหวาย แวนด้า ออนซิเดียม และวาสโคสไตริส บนพื้นที่ ๓ ไร่ จำนวน ๓ แปลง เพื่อจำหน่ายและสนับสนุนการท่องเที่ยวเชิงเกษตร
                                </div>
                                <div>
                                    <strong style={{paddingLeft: 35}}>๕. การปลูกไม้ประดับ</strong><br/>
                                        <strong style={{paddingLeft: 65}}>๕.๑. การปลูกไม้ประดับ </strong><br/>
                                        ได้ปลูกไม้ประดับต่างๆ และเพาะชำกล้าไม้ชนิดต่างๆ จำหน่ายและแจกจ่ายด้วย
                                </div>
                                <div>
                                    <strong style={{paddingLeft: 35}}>๖. การปลูกสมุนไพร </strong>
                                    ได้ทำแปลงสวนสมุนพรสาธิตรอบอาศรมพระดาบส บนพื้นที่ ๕ ไร่                                    
                                </div>
                                <Row>
                                        <Col lg="6"><img src={Pic7} alt="" className='img-full my-3'/></Col>
                                        <Col lg="6"><img src={Pic8} alt="" className='img-full my-3'/></Col>
                                </Row>
                                <div>
                                    <strong style={{paddingLeft: 35}}>๗. การปศุสัตว์</strong><br/>
                                        <strong style={{paddingLeft: 65}}>๗.๑. การเลี้ยงโคเนื้อ </strong>
                                        ได้เลี้ยงโคเนื้อและกระบือ เพื่อสนับสนุนการฝึกปฏิบัติของศิษย์พระดาบสสาขางานปศุสัตว์ และได้ปุ๋ยคอกเพื่อปรับปรุงบำรุงดิน<br/>
                                        <strong style={{paddingLeft: 65}}>๗.๒. การเลี้ยงไก่ไข่ </strong>
                                        ได้เลี้ยงไก่ไข่บนบ่อปลานิล จำนวน ๔๐๐ ตัว เพื่อเป็นอาหารศิษย์พระดาบส และเป็นที่ฝึกงานของศิษย์พระดาบสสาขาปศุสัตว์<br/>
                                        <strong style={{paddingLeft: 65}}>๗.๓. การเลี้ยงไก่ชนไทยเชิงอนุรักษ์ </strong>
                                        ได้เลี้ยงไก่ชนไทยสายพันธุ์แสมดำที่มีผู้น้อมเกล้าฯ ถวายสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารีเพื่อการอนุรักษ์ โดยให้เศษปลายข้าว และรำจากเครื่องสีข้าวของโครงการฯ<br/>
                                </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>แปลงสาธิตเกษตรทฤษฎีใหม่</Accordion.Header>
                                <Accordion.Body className='text-left font-span'>
                                    <span style={{ paddingLeft: 35 }}>ได้จัดทำแปลงสาธิตการทำการเกษตรพอเพียง</span>
                                         (เกษตรทฤษฎีใหม่ขั้นที่ ๑) บนพื้นที่ ๓ ไร่ เพื่อเป็นต้นแบบในการนำปรัชญาของเศรษฐกิจพอเพียงไปสู่การปฏิบัติสำหรับเกษตรกรรายย่อย ให้เกษตรกร นิสิต นักศึกษา และผู้ที่สนใจเข้ามาศึกษาดูงาน
                                    
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>การสร้างสวนสาธารณะเฉลิมพระเกียรติ ๘๔ พรรษา</Accordion.Header>
                                <Accordion.Body className='text-left font-span'>
                                    <span style={{ paddingLeft: 35 }}>โครงการลูกพระดาบศ</span>
                                    ได้จัดสร้างสวนสาธารณะในพื้นที่ไว้ให้เป็นสถานที่พักผ่อนหย่อนใจของชุมชน บนพื้นที่ ๙ ไร่ เพื่อเป็นกิจการเฉลิมพระเกียรติ เพื่อเป็นกิจกรรมเฉลิมพระเกียนติ
                                    เนื่องในโอกาสมหามงคลเฉลิมพระชนพรรษาครบ ๗ รอบ ๘๔ พรรษา ซึ่งได้ปลูกและรวบรวมพันธุ์พืชที่สามารถทนต่อสภาพดินเค็ม เพื่อเป็นแหล่งเรียนรู้พีนธุ์พืชดังกล่าว
                                    ในรูปแบบสวนพฤกษศาสตร์ระบบนิเวศน้ำกร่อย สร้างอาคารอนเกประสงค์ เส้นทางศึกษาธรรมชาติ ลานกิจกรรม ร้านจำหน่ายผลิตภัณฑ์ ห้องสุขา ฯลฯ
                                    สามารถรองรับการทำกิจกรรมกลางแจ้ง และสนับสนุนกิจกรรมการท่องเที่ยวเชิงเกษตร
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>กิจกรรมการสาธิตการใช้พลังงานทดแทน</Accordion.Header>
                                <Accordion.Body className='text-left font-span'>
                                    <div>
                                        <strong style={{paddingLeft: 35}}>๑. การผลิตไฟฟ้าจากพลังงานแสงอาทิตย์ </strong>
                                        ได้ติดตั้งแผงโซล่าเซลล์บนอาคารสถานีเพาะพันธ์ปลา กำลังผลิต ๖ กิโลวัตต์/ชั่วโมง นำไฟฟ้าที่ผลิตด้ใช้ในโครงการฯ<br/>
                                        <strong style={{paddingLeft: 35}}>๒. การผลิตไฟฟ้าจากพลังงานลม </strong>
                                        ได้ติดตั้งกังหันลมขนาดต่างๆ ผลิตไฟฟ้าใช้ในโครงการฯ เพื่อลดค่าใช้จ่ายของโครงการฯ<br/>
                                        <strong style={{paddingLeft: 35}}>๓. การสูบน้ำจากพลังงานลม </strong>
                                        ได้ติดตั้งกังหันลมแบบหอสูง เพื่อลดค่าใช้จ่ายในการนำน้ำเข้าออกโครงการฯ<br/>
                                        <strong style={{paddingLeft: 35}}>๔. การผลิตไบโอดีเซล </strong>
                                        ได้ผลิตไบโอดีเซลขนาดกำลังผลิต ๑๐๐ ลิตร/วัน เพื่อใช้กับรถยนต์ขนาดเล็ก สำหรับตีน้ำในบ่อกุ้งและเครื่องจักรกลการเกษตรภายในโครงการฯ<br/>
                                        <strong style={{paddingLeft: 35}}>๕. เตาประหยัดพลังงาน </strong>
                                        ได้ติดตั้งตู้อบก้อนเชื้อเห็ดและเตาทำอาหารปลา แบบประหยัดพลังงาน<br/>
                                        <strong style={{paddingLeft: 35}}>๖. การตากปลาสลิดในโรงอบแห้ง </strong>
                                        ใช้พลังงานแสงอาทิตย์ มีการทดลองตากปลาสลิดหอมทองในโรงอบแห้งแบบอุโมงค์ ทำด้วยโพลีคาร์บอเนต<br/>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>การวิจัย และพัฒนา</Accordion.Header>
                                <Accordion.Body className='text-left font-span'>
                                    <span style={{ paddingLeft: 35 }}>ได้รับงบประมาณ</span>
                                    จากสำนักงานคณะกรรมการวิจัยแห่งชาติ เพื่อศึกษาวิจัยและพัฒนาองกรค์ความรู้ เกี่ยวกับการเกษตร ประมง การเพิ่มมูลค่าสินค้าการเกษตร
                                    แปรรูปปลาสลิด พลังงานทดแทน และอื่นๆ ในพื้นที่
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Result