import FacebookLogin from 'react-facebook-login';
import React, {useState} from 'react'
import { appId, apiLink } from '../constants/DataPublic';
import {useNavigate} from 'react-router-dom';
import FB from '../assets/images/facebook.svg';

import useLogin from '../customHook/userLogin';

export const FBLogin = (props) => {

    let userLogin = {}
    let { refreshPage } = useLogin();

    const navigate = useNavigate();

    const componentClicked = () =>{
        console.log('clicked');
    }

    const responseFacebook = async (response) =>{  

        function FBProfile(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
              var reader = new FileReader();
              reader.onloadend = function() {
                callback(reader.result);
              }
              reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        FBProfile(response.picture["data"]["url"], function(dataUrl) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/JSON',
                    'Access-Control-Allow-Origin': 'https://lukphradabos.com/'
                },
                body: JSON.stringify({
                    "provider":"Facebook",
                    "provider_id":response.userID,
                    "firstname": response.first_name,
                    "lastname":response.last_name,
                    "email":response.email,
                    "image64": dataUrl
                })
            };

            console.log("dataUrl", dataUrl);
            // console.log("picture", response.picture["data"]["url"]); 

            fetch (apiLink+"/member/loginSocial", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result["result"] === true){
                    console.log("เข้าสู่ระบบสำเร็จ")
                    userLogin = {
                        "member_id": result["data"][0].member_id,
                        "title": result["data"][0].title,
                        "firstname": result["data"][0].firstname,
                        "lastname": result["data"][0].lastname,
                        "email": result["data"][0].email,
                        "idcard": result["data"][0].idcard,
                        "mobile": result["data"][0].mobile,
                        "lineid": result["data"][0].lineid,
                        "food_condition": result["data"][0].food_condition,
                        "profile_image": result["data"][0].profile_image,
                        "password": result["data"][0].password
                    }
                    sessionStorage.setItem('UserLogin',JSON.stringify(userLogin))
                    navigate("/")
                    refreshPage()
                } else {
                    console.log("เข้าสู่ระบบไม่สำเร็จ")
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
        }) 
    }

    return (
        <div>
            <FacebookLogin
                appId={appId}
                autoLoad={false}
                cssClass="btn-login mb-3 btn btn-outline-primary btn-lg width-full"
                fields="first_name,last_name,email,picture"
                icon={<img src={FB} alt="" width={30}/>}
                textButton={<span style={{paddingLeft: 20}}>เข้าระบบด้วย Facebook</span>}
                onClick={componentClicked}
                callback={responseFacebook}
                />
        </div>
    )
}
export default FBLogin