import React ,  { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Camera from '../assets/images/camera.png'

import { apiLink } from '../constants/DataPublic'
import useRegister from '../customHook/useRegister';
import useLogin from '../customHook/userLogin';

const Profile = (props) => {

    let userLogin = {}

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(()=>{
        if (user === null){
        navigate("/")
        } 
        else {
            setSelectedTitle(user.title)
            setFirstname(user.firstname)
            setLaastname(user.firstname)
            setEmail(user.email)
            setIDcard(user.idcard)
            setMobile(user.mobile)
            setLineid(user.lineid)
            // setFood(user.food_condition)
        }
    },[user])


    const [titles, setTitles] = useState([]);
    
    const [selectedTitle, setSelectedTitle] = useState('');
    const [firstname, setFirstname] = useState('')
    const [lastname, setLaastname] = useState('')
    const [email, setEmail] = useState('')
    const [idcard, setIDcard] = useState('')
    const [mobile, setMobile] = useState('')
    const [lineid, setLineid] = useState('')
    const [food, setFood] = useState(null)

    
    
    const navigate = useNavigate();
    const [imageUpload, setImageUpload] = useState(null);
    

    const getApiTitle = async () => {
        try {
            const requestOption = {
                "method": "GET"
            }
            const response = await fetch(apiLink+"/member/showTitle" , requestOption).then((response) => response.json());
            setTitles(response.data);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getApiTitle();
    }, []);

    const refreshPage = () =>{ 
        window.location.reload(); 
    }

    const editImgUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const dataUrl = reader.result;
              setImageUpload(dataUrl);
            };
            reader.readAsDataURL(file);
          } else {
            setImageUpload(null);
          }

        // console.log(imageUpload)
    };



    const editMember = (e) => {

        const image64 = imageUpload ? imageUpload.split(',')[1] : null;
        // console.log("image64", image64)
        // console.log("imageUpload", imageUpload)

        const body = JSON.stringify({
                "member_id": user.member_id,
                "title": selectedTitle, 
                "firstname": firstname, 
                "lastname": lastname, 
                "idcard": idcard, 
                "mobile": mobile, 
                "lineid" :lineid,
                "food_condition" : food,
                "image64" : image64,
        })

        // console.log(image64)
    
        var requestOptions = {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: body,
        };

        fetch (apiLink+'/member/editMember', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result["result"] === true){
                    console.log(result["profile_image"])
                    userLogin = {
                        "member_id": user.member_id,
                        "title": selectedTitle,
                        "firstname": firstname,
                        "lastname": lastname,
                        "email": email,
                        "idcard": idcard,
                        "mobile": mobile,
                        "lineid": lineid,
                        "food_condition": food,
                        "profile_image": imageUpload,
                        "password": user.password
                    }
                      // console.log(result["data"][0].member_id)
                    sessionStorage.setItem('UserLogin',JSON.stringify(userLogin))
                    refreshPage()
                    
                    console.log(body)

                } else {
                    console.log("NO")
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    }

    const deleteImg = (e) => {
        setImageUpload(null)
    }
    
    
    

    return (
        <div className="bg">
            {user != null && (
            <Container>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='white-box my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>ข้อมูลสมาชิก</h1>
                        <Col className='margin-auto mb-5'>
                                <div>
                                    {user.profile_image ? (
                                        <div className="box-profile">
                                            <img
                                            src={apiLink + user.profile_image}
                                            className="img-profile"
                                            alt="Uploaded"
                                            />
                                        </div>
                                    ) : (
                                        <div className="box-profile"></div>
                                    )}
                                </div>
                            {/* <input type="file" name='image64' accept="image/*" id="select-image" style={{display: 'none'}} onChange={editImgUpload}/>
                            
                                <div>
                                    {user.profile_image ? (
                                    <div className="box-profile">
                                        <img
                                        src={(imageUpload || apiLink + user.profile_image)}
                                        className="img-profile"
                                        alt="Uploaded"
                                        />
                                    </div>
                                    ) : imageUpload ? (
                                    <div className="box-profile">
                                        <img src={imageUpload} className="img-profile" alt="Uploaded" />
                                    </div>
                                    ) : (
                                    <div className="box-profile"></div>
                                    )}
                                </div>

                            <div className='box-camera mb-2'>
                                <label htmlFor="select-image" className='select-img'>
                                    <img src={Camera} alt="" width={20}/>
                                </label>
                            </div>
                            {imageUpload ? (
                                <div className='font-span mb-5 text-center pointer' style={{color: "#C18A61"}} onClick={deleteImg}>ลบรูปภาพ</div>
                            ) : (
                                <div></div>
                            )} */}
                        </Col>

                        <Form className='px-05'>
                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>คำนำหน้า *</Form.Label>
                                <Form.Select aria-label="Default select example input-login" name="title" value={selectedTitle} onChange={e => setSelectedTitle(e.target.value)}>
                                    <option key='001' value={user.title}>{user.title}</option>
                                    {titles.length > 0 && titles.map((data) => {
                                        return <option key={data.title_id} value={data.title_name}>{data.title_name}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                              

                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>ชื่อ *</Form.Label>
                                <Form.Control type="text" placeholder="" defaultValue={firstname} onChange={e => setFirstname(e.target.value)} className='input-login' />
                            </Form.Group>

                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>นามสกุล *</Form.Label>
                                <Form.Control type="text" placeholder="" defaultValue={lastname} onChange={e => setLaastname(e.target.value)} className='input-login' />
                            </Form.Group>

                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>อีเมล์ *</Form.Label>
                                <Form.Control type="email" placeholder="" defaultValue={email} onChange={e => setEmail(e.target.value)} className='input-login' disabled />
                            </Form.Group>

                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>เลขบัตรประจำตัวประชาชน *</Form.Label>
                                <Form.Control type="number" maxLength={13} defaultValue={idcard} onChange={e => setIDcard(e.target.value)} placeholder="" className='input-login' />
                            </Form.Group>

                            <Form.Group className="mb-3 text-left">
                                <Form.Label className='px-3 regis-lable'>เบอร์โทรศัพท์ *</Form.Label>
                                <Form.Control type="number" maxLength={10} defaultValue={mobile} onChange={e => setMobile(e.target.value)} placeholder="" className='input-login' />
                            </Form.Group>

                            <Form.Group className="mb-4 text-left">
                                <Form.Label className='px-3 regis-lable'>ไลน์ไอดี</Form.Label>
                                <Form.Control type="text" placeholder="" defaultValue={lineid} onChange={e => setLineid(e.target.value)} className='input-login'/>
                            </Form.Group>

                            <Form.Group className="mb-4 text-left">
                                <Form.Label className='px-3 regis-lable'>ข้อจำกัดด้านอาหาร :</Form.Label>
                                    <Form.Check type="radio" name="group1" id="" checked={food === ""} onChange={e => setFood(e.target.value)} value="" hidden/>
                                    <Form.Check type="radio" name="group1" label="ไม่มี" id="" checked={food === "1"} onChange={e => setFood(e.target.value)} value="1" />
                                    <Form.Check type="radio" name="group1" label="รับประทานอาหารอิสลามเท่านั้น" id="" checked={food === "2"} onChange={e => setFood(e.target.value)} value="2" />
                                    <Form.Check type="radio" name="group1" label="รับประทานอาหารมังสวิรัติเท่านั้น" id="" checked={food === "3"} onChange={e => setFood(e.target.value)} value="3" />
                                    <Form.Check type="radio" name="group1" label="รับประทานอาหารเจเท่านั้น" id="" checked={food === "4"} onChange={e => setFood(e.target.value)} value="4" />
                                    <Form.Check type="radio" name="group1" label="ไม่รับประทานเนื้อไก่" id=""  checked={food === "5"} onChange={e => setFood(e.target.value)} value="5" />
                            </Form.Group>

                            <div className="d-grid gap-2 mb-3">
                                <Button variant="" size="lg" className='btn-saveprofile font-btn' onClick={editMember}>บันทึกข้อมูล</Button>
                            </div>
                            <div className="d-grid gap-2">
                                <Button variant="" size="lg" className='btn-course font-btn'>ดูหลักสูตรที่สมัคร</Button>
                            </div>
                        </Form>
                        
                    </Col>
                </Row>
            </Container>
            )}
        </div>
    )
}
export default Profile