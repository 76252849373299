import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import useLogin from '../customHook/userLogin';

import Close from '../assets/images/closed.png'

const Forget = (props) => {

    let { show, showfalse, forget, message, forgetPassword, handleClosefalse, setForget, handleClose } = useLogin();

    return (
        <div className="bg">
            <Container style={{height: "100vh", display: "grid", alignItems: "center"}}>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='white-box my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>กรอกข้อมูลลืมรหัสผ่าน</h1>
                        <Form className='px-05'>
                            <Form.Group className="mb-3 text-left">
                                <Form.Control type="text" name='key' placeholder="เลขบัตรประชาชน 13 หลัก หรือ อีเมล์" className='text-center input-login mb-3' value={forget} onChange={e => setForget(e.target.value)} required/>
                            </Form.Group>
                            <p className='my-2 message'>{message}</p>
                            <div className="d-grid gap-2">
                                <Button variant="primary" size="lg" className='btn-login font-btn' onClick={forgetPassword}>ลืมรหัสผ่าน</Button>
                            </div>
                        </Form>

                        <Modal show={show} onHide={handleClose} className="register">
                            <Modal.Header style={{borderBottom: 0, justifyContent: "end"}}>
                                <Link to="/"><img src={Close}/></Link>
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <div>
                                    ระบบส่งข้อความยืนยันไปที่อีเมล์ของคุณ 
                                    กรุณากด “เปลี่ยนรหัสผ่าน” ในอีเมล์ที่ลงทะเบียน 
                                    เพื่อเปลี่ยนรหัสผ่าน
                                </div>
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>

                        <Modal show={showfalse} onHide={handleClosefalse} className="register">
                            <Modal.Header style={{borderBottom: 0, justifyContent: "end"}}>
                                <Link to="/login" ><img src={Close}/></Link>
                            </Modal.Header>
                            <Modal.Body className='text-center text-popup px-05'>
                                <p>ลิ้งค์ของคุณหมดอายุแล้ว</p>
                            </Modal.Body>
                            <Modal.Footer style={{borderTop: 0}}></Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Forget