import React ,  { useEffect, useState } from 'react'
import { GoogleLogin } from 'react-google-login'
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import { gapi } from 'gapi-script';

import { clientId, apiLink } from '../constants/DataPublic';
import useLogin from '../customHook/userLogin';

import GG from '../assets/images/google.png'

export const GGLogin = (props) => {

    let userLogin= {}
    let { refreshPage } = useLogin();
    const navigate = useNavigate();

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            })
        }
        gapi.load("client:auth2", initClient)
    },[])


    const onSuccess = async (res) => {
        // let ggUser = res.profileObj;
        // props.getGGUser(ggUser)
        const {googleId, familyName, givenName, email, imageUrl} = res.profileObj;

        function GGProfile(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
              var reader = new FileReader();
              reader.onloadend = function() {
                callback(reader.result);
              }
              reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }
          
        GGProfile(imageUrl, function(dataUrl) {
            const requestOptions = {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    "provider": "Google",
                    "provider_id": googleId,
                    "firstname": givenName,
                    "lastname": familyName,
                    "email": email,
                    "image64": dataUrl
                 }),
            };

            console.log("dataUrl", dataUrl)

            fetch (apiLink+"/member/loginSocial", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result["result"] === true){
                    console.log("เข้าสู่ระบบสำเร็จ")
                    userLogin = {
                        "member_id": result["data"][0].member_id,
                        "title": result["data"][0].title,
                        "firstname": result["data"][0].firstname,
                        "lastname": result["data"][0].lastname,
                        "email": result["data"][0].email,
                        "idcard": result["data"][0].idcard,
                        "mobile": result["data"][0].mobile,
                        "lineid": result["data"][0].lineid,
                        "food_condition": result["data"][0].food_condition,
                        "profile_image": result["data"][0].profile_image,
                        "password": result["data"][0].password
                    }
                    // console.log("data =>", result["data"][0].member_id)
                    sessionStorage.setItem('UserLogin',JSON.stringify(userLogin))
                    navigate("/")
                    refreshPage()
                } else {
                    console.log("เข้าสู่ระบบไม่สำเร็จ")
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
        })
    }

    const onFailure = (res) => {
        console.log("LOGIN FAILED! res: ", res);
    }

    return (
        <div>
            <GoogleLogin 
                clientId={clientId}
                render={renderProps => (
                    <Button onClick={renderProps.onClick} variant="outline-primary" size="lg" className='btn-login mb-3 width-full'>
                        <img src={GG} alt="" width={30}/> 
                        <span style={{paddingLeft: 20}}>เข้าระบบด้วย Google</span>
                    </Button>
                )}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy='single_host_origin'
                isSignedIn={false}
            />
        </div>
    )
}
export default GGLogin