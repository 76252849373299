import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Philosophy = () => {
  return (
    <div className="bg">
            <Container>
                <Row className='justify-content-center'>
                    <Col xs="11" md="11" lg="9" xl="7" className='box-content my-5 py-5 text-center'>
                        <h1 className='font-title mb-4'>ปรัชญา</h1>
                        <Col lg="10" className='mb-5 margin-auto' style={{ border: "3px solid #C18A61"}}>
                            <div className='m-2 p-3' style={{ background: "rgba(193, 138, 97, 0.57)", border: "3px solid #C18A61" }}>
                                <h1 className='font-title'>สนองพระราชดำริ ให้โอกาสที่สอง<br />สร้างคนดี มีวิชาชีพ กลับคืนสู่สังคม </h1>
                            </div>
                        </Col>

                        <h1 className='font-title mb-4'>วิสัยทัศน์</h1>
                        <Col lg="10" className='text-center font-span margin-auto mb-5'>
                            โครงการลูกพระดาบส จะเป็นศูนย์กลางการเรียนรู้และถ่ายทอดเทคโนโลยีทางด้านการเกษตร สมุนไพร บนพื้นดินเค็มกับน้ำกร่อย การเพิ่มมูลค่าสินค้าเกษตร การใช้พลังงานทดแทน และอื่นๆ เป็นองค์กรที่มีการบริหารจัดการอย่างดีเยี่ยม มีบุคลากรที่มีคุณภาพ สามารถสนองพระราชดำริได้อย่างมีประสิทธิภาพสูงสุด
                        </Col>

                        <h1 className='font-title mb-4'>พันธกิจ</h1>
                        <Col lg="9" className='font-span margin-auto mb-5'>
                            <span style={{paddingLeft: "20px"}}>๑.</span> จัดการเรียนการสอน ฝึกอบรมศิษย์พระดาบสหลักสูตร การเกษตรพอเพียง และช่างไม้เครื่องเรือนได้อย่างมีคุณภาพ<br/>
                            <span style={{paddingLeft: "20px"}}>๒.</span> ดำเนินกิจกรรมการเกษตรในลักษณะพึ่งพาตนเองตามแนว พระราชดำริปรัชญาเศรษฐกิจพอเพียง สร้างรายได้สนับสนุนมูลนิธิ พระดาบส<br/>
                            <span style={{paddingLeft: "20px"}}>๓.</span> ศึกษาวิจัยและพัฒนาองค์ความรู้และเทคโนโลยีทางด้านเกษตร การเพิ่มมูลค่าสินค้าเกษตร สมุนไพร การใช้พลังงานทดแทน และอื่นๆ<br/>
                            <span style={{paddingLeft: "20px"}}>๔.</span> ถ่ายทอดองค์ความรู้ และเทคโนโลยีทางด้านการเกษตร การเพิ่ม มูลค่าสินค้าเกษตร สมุนไพร การใช้พลังงานทดแทน<br/>
                            <span style={{paddingLeft: "20px"}}>๕.</span> เป็นสถานที่ท่องเที่ยวเชิงเกษตรในพื้นที่จังหวัดสมุทรปราการ
                        </Col>
                    </Col>
                </Row>
            </Container>
        </div>
  )
}
export default Philosophy