import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const PageNotFound = () => {
  return (
    <div className="bg">
        <Container style={{height: "100vh", display: "grid", alignItems: "center"}}>
            <Row className='justify-content-center'>
                <Col className='my-5 py-5 text-center'>
                    <h1 className='font-title mb-4'>ไม่พบหน้าที่คุณเรียก</h1>
                    <Button href='/' className='btn btn-login font-btn btn-primary btn-lg' style={{color: "#fff"}}>กลับสู่หน้าหลัก</Button>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
export default PageNotFound