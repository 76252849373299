import React ,  { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

import Map from '../assets/images/map.jpg'
import { apiLink } from '../constants/DataPublic';

const Contact = () => {

  const [contact, setContact] = useState([]);

  useEffect(() => {
    getApiContact();
  }, []);

  const getApiContact = async () => {
    try {
        const requestOption = {
            "method": "GET"
        }
    
        const response = await fetch(
            apiLink+"/contact/showContact" , requestOption).then((response) => response.json());
        setContact(response.data);
    } catch (err) {
        console.log(err);
    }
  };

  return (
    <div className="bg">
      <Container>
      {contact.map((data)=>{
        return (
          <Row className='justify-content-center' key={data.name}>
            <Col xs="11" md="11" lg="9" xl="7" className='box-content my-5 py-5 text-center px-05'>
              <h1 className='font-title mb-4'>ติดต่อโครงการ</h1>
                <div className='text-left font-span'>
                  <strong>ติดต่อทางโทรศัพท์ : </strong>{data.telephone} หรือ <strong>ทางโทรสาร :</strong> {data.fax}<br/>
                  <strong>ติดต่อทางอีเมล์ : </strong>{data.email}<br/>
                  <strong>ติดต่อทางไปรษณีย์ : </strong>{data.address}
                </div>
                <div className='text-left font-span'>
                  <strong>แผนที่โครงการ : </strong>
                  <img src={Map} alt="" className='img-full my-3'/>
                  <Accordion defaultActiveKey={['0']} flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>วิธีการเดินทางไป{data.name}</Accordion.Header>
                      <Accordion.Body className='text-left font-span'>
                        <div>
                          <strong>วิธีที่ ๑ รถยนต์โดยสาร</strong>
                          <ul>
                            <li>
                              รถโดยสาร จากอนุสาวรีย์ชัยฯ โดยสาร ปอ.๕๓๗ ไปนิคมอุตสาหกรรมเมืองใหม่บางพลี และต่อแท็กซี่มาที่ซอยเทศบาลบางปู ๑๑๙ 
                              (มหาวิทยาลัยราชภัฏธนบุรี สมุทรปราการ) กิโลเมตรที่ ๔๙ เข้ามาประมาณ ๑.๖ กิโลเมตร จะอยู่ซ้ายมือ
                            </li>
                            <li>
                              รถโดยสารสาย ๕๓๖ อนุสาวรีย์ชัยฯ-ปากน้ำ แล้วต่อรถตู้ สายสำโรง-คลองด่าน ลงซอยเทศบางบางปู ๑๑๙ เข้ามาประมาณ ๑.๖ กิโลเมตรจะอยู่ซ้ายมือ
                            </li>
                          </ul>
                        </div>
                        <div>
                          <strong>วิธีที่ ๒ รถยนต์ส่วนตัว</strong>
                          <ul>
                            <li>
                              รถยนต์ส่วนตัว ใช้เส้นทางบางนา-ตราด มุ่งหน้าไปชลบุรี (หากใช้เส้นทางด่วนบูรพาวิถีลงตรงด่าน เมืองใหม่บางพลี) 
                              เมื่อถึงกิโลเมตรที่ ๑๙ ใช้ทางคู่ขนานเพื่อกลับรถ กิโลเมตรที่ ๒๐ ให้ตรงไป ๑ กิโลเมตร เลี้ยวซ้ายเข้าทางคลองระบายน้ำ 
                              ท่าอากาศยานสุวรรณภูมิ ตรงมาอีกประมาณ ๑๐ กิโลเมตร ให้เลี้ยวซ้ายบริเวณสะพานคลองสี่ ขับมาตามเส้นทางประมาณ ๒ กิโลเมตร 
                              โครงการฯ จะอยู่ขวามือ
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
            </Col>
        </Row>
        )
      })}
       
      </Container>
    </div>
  )
}
export default Contact
